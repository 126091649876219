App.initSecretToggle = function () {
    function copy() {
        const elements = $(this).parent().children();
        const originalText = $(elements).filter('.original')
        const copyIcon = $(elements).filter('.copy-icon')

        navigator.clipboard.writeText(originalText.text()).then(() => {
            copyIcon.addClass('copied');
            setTimeout(function () {
                copyIcon.removeClass('copied');
            }, 800);
        });
    }

    $(document).on('click', '.eye-icon', function () {
        const span = $(this).siblings('span');
        const originalText = span.filter('.original');
        const maskedText = span.filter('.masked');

        if (originalText.is(':visible')) {
            originalText.hide();
            maskedText.show();
            $(this).removeClass('visible');
        } else {
            originalText.show();
            maskedText.hide();
            $(this).addClass('visible');
        }
    });

    $(document).on('click', '.copy-icon', copy);
    $(document).on('click', '.original', copy);
    $(document).on('click', '.masked', copy);

}
